import React from 'react';

import ContactForm from "../components/contactform"
import ProductCategories from "../components/productcategories"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Menu from "../components/menu"

const ContactInformationpage = () => {
    return (
        <Layout>
            <SEO title="Antikviteter, Lyxiga klockor, smycken & antika tavlor" />
            
            <Menu top={true} />
            <section className="about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-9">
                            <h2>Kontaktformulär</h2>
                            <form method="post" name="contact-form" data-netlify="true">
                                {/* You still need to add the hidden input with the form name to your JSX form */}
                                <input type="hidden" name="form-name" value="contact-form" />
                                <div className="form-row">
                                    <div className="col-12 mt-3 mt-lg-0">
                                        <label>För- och efternamn</label>
                                        <input type="text" name="name" className="form-control" />
                                    </div>
                                    <div className="col-12 col-lg-6 mt-3">
                                        <label>E-mail</label>
                                        <input type="text" name="email" className="form-control" />
                                    </div>
                                    <div className="col-12 col-lg-6 mt-3">
                                        <label>Telefonnr.</label>
                                        <input type="text" name="phone" className="form-control" />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>Ämne</label>
                                        <input type="text" name="subject" className="form-control" />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>Meddelande</label>
                                        <textarea name="message" className="form-control" />
                                    </div>
                                    <div className="col-12 mt-3 text-right">
                                        <input type="submit" value="Skicka meddelande" className="btn btn-primary" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-lg-3 pb-4 pb-lg-0 mb-4 mb-lg-0 order-first order-lg-last">
                            <h2>Kontakt information</h2>
                            <div className="d-block pb-3"><a href="callto:0734000062" target="_blank">073 400 00 62</a></div>
                            <div className="d-block"><a href="mailto:info@antikviteten.se" target="_blank">info@antikviteten.se</a></div>
                        </div>
                    </div>
                </div>
            </section>
            <ProductCategories />
            <ContactForm 
                text={{ 
                h2: 'Behov av värdering? – Svar inom 24h',
                h3: 'Oavset om det är en stor eller liten pjäs så hjälper vi till med värdering.'
                }} 
            />
            <Footer />
        </Layout>
    );
};

export default ContactInformationpage;